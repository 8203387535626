<template>
  <b-form-group
    label-cols="12"
    label-cols-lg="2"
    label-size="sm"
    :label="subject.title"
    :label-for="subject.id"
  >
    <b-form-select
      :id="subject.id"
      v-model="selected"
      :state="validateError ? false : null"
      :options="options"
      :disabled="subject.disabled"
    ></b-form-select>
    <small v-if="subject.memo" class="form-text text-muted">
      {{ subject.memo }}
    </small>
    <b-form-invalid-feedback :state="!validateError">
      輸入的資料有誤或未填寫，請確認
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import collectionApi from "@/apis/collection";
import Collection from "@/models/Collection.model.js";

export default {
  props: {
    subject: {
      type: Object,
      required: true,
    },
    // value: [String, Number, Array, Object],
    value: {
      type: Array,
      default: () => {[]},
    },
    validateError: {
      type: Boolean,
    },
  },
  data() {
    return {
      options: [],
      collection: null,
    }
  },
  watch: {
    subject: {
      handler: function (val) {
        console.log(val);
        if (val.collection_id) {
          this.fetchCollectionItems();
        }
      },
      immediate: true,
    },
  },
  computed: {
    selected: {
      get() {
        if (Array.isArray(this.value)) {
          return this.value[0];
        }
        return this.value !== null ? [this.value][0] : [];
      },
      set(v) {
        this.$emit("input", Array.isArray(v) ? v : [v]);
      }
    }
  },
  methods: {
    async fetchCollectionItems() {
      // fetch collection items
      try {
        const response = await collectionApi.getCollection(this.subject.collection_id);

        this.collection = response.data.data;

        const collectionModel = new Collection(this.collection)

        this.options = this.collection.items.map((item) => {
          return {
            value: {
              type: 'collection_select',
              collection_item_id: item.id,
              collection_id: this.collection.id,
              text: collectionModel.textForOption(item.id),
            },
            text: collectionModel.textForOption(item.id),
          };
        });
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", `讀取 ${this.subject.title} 選項時發生錯誤，請重新整理後再試一次`, "error");
      }
    }
  }
}
</script>
